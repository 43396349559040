import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import { Helmet, useTranslation } from 'gatsby-plugin-react-i18next'
import MainLayout from '../components/main-layout'
import Header from '../components/header/header'

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`

interface Error404PageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        titleTemplate: string
      }
    }
  }
}

const Error404Page: React.FC<Error404PageProps> = (props) => {
  const { t } = useTranslation()
  return (
    <MainLayout>
      <Helmet title={t(`Page not found`)} titleTemplate={props.data.site.siteMetadata.titleTemplate} />
      <Header />
      <div className="not-found-page container-lg">
        <h1>{t(`Page not found`)}</h1>
        <h2>{t(`page_not_found_text`)}</h2>
      </div>
    </MainLayout>
  )
}

export default Error404Page
